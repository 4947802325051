import React, { useState } from "react";

const FaqItem = ({ faq }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="faq-item-container">
      <div
        className={`faq-item-header ${
          open ? "faq-item-header-opened" : "faq-item-header-closed"
        }`}
        role="button"
        tabindex="0"
        onClick={() => setOpen(!open)}
      >
        <span>{faq.question}</span>

        <i
          className="faq-item-arrow"
          style={{ transform: open ? "rotate(-135deg)" : "rotate(45deg)" }}
        ></i>
      </div>
      {open ? (
        <div
          className="faq-item-content"
          dangerouslySetInnerHTML={{
            __html: faq.answer,
          }}
        ></div>
      ) : null}
    </div>
  );
};

export const Faqs = ({ faqs, title }) => {
  if (!faqs.length) return null;

  return (
    <section className="container page-width">
      <div className="faq-card card-primary">
        <h2>{title}</h2>

        <div className="faqs-container">
          {faqs.map((faq) => (
            <FaqItem faq={faq} />
          ))}
        </div>
      </div>
    </section>
  );
};
