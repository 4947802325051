import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import Countries from "components/Countries";
import Partenaires from "components/Partenaires";
import { slugify, removeSpaces } from "utils/index";
import { StaticImage } from "gatsby-plugin-image";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Faqs } from "components/Faqs";

/* Markdown Converter */
const showdown = require("showdown");
const converter = new showdown.Converter();

const PageContent = ({ content, sidebarContent }) => {
  const { _numbers, _partners, _staticTexts } = content;

  const contentBody = useMemo(() => {
    if (content && content.contentBody) return content.contentBody;

    return "";
  }, [content]);

  return (
    <>
      <main className="container page-width">
        <div className="page2-card">
          <div className="page2-box1">
            <div className="page2-card-left card-primary">
              <h1 className="page2-left-text h2-title">{content.h1}</h1>

              <div className="page2-left-content">
                <div className="companies-icon float-left">
                  <LazyLoadImage
                    width={141}
                    height={141}
                    src={`https:${content.image.fields.file.url}`}
                    alt={content.altText}
                  />
                </div>
                <div
                  className="companies-text"
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(content.body),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="page2-box2 hide-mobile">
            <div className="page2-card-right card-primary">
              <div className="page2-right-text">
                <h3>{sidebarContent.title}</h3>
                <div
                  className="connexion-spacing"
                  style={{ marginTop: "15px" }}
                >
                  <h5>{sidebarContent.quote}</h5>
                  <span>{sidebarContent.signature}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <section className="container page-width">
        <div className="instruction-card card-secondary">
          <div className="numeros-h2">
            <h2>
              {_staticTexts.numbersFrom} {content.menuTitle}
            </h2>
          </div>

          <div className="numeros-box">
            {_numbers.map((number, index) => (
              <div className="numeros-list" key={index}>
                <h3 className="companies-span-left">
                  {number.service_text || "Standard"}:{" "}
                </h3>
                <h3 className="companies-span">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href={`tel:${removeSpaces(number.phone_number_text)}`}
                  >
                    {number.phone_number_text}
                  </a>
                </h3>
              </div>
            ))}
          </div>
        </div>
      </section>

      {contentBody.length ? (
        <section className="container page-width">
          <div className="card-primary">
            <div className="override-content">
              <div
                dangerouslySetInnerHTML={{
                  __html: contentBody,
                }}
              ></div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className="container page-width">
            <div className="services-card card-primary">
              <div className="activites-box">
                <div className="services-card-left">
                  <div className="h2-title">{_staticTexts.companyServices}</div>
                </div>
                <div
                  className="services-card-right"
                  dangerouslySetInnerHTML={{
                    __html: content.servicesBody,
                  }}
                />
              </div>
            </div>
          </section>

          <section className="container page-width">
            <div className="activites-card card-primary">
              <div className="activites-box">
                <span className="activites-card-left">
                  <div className="activites-card-h2 h2-title">
                    {_staticTexts.companyActivities}
                  </div>

                  <span
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(content.activitesBody),
                    }}
                  />
                </span>
                <span className="activites-card-right">
                  <StaticImage
                    src="../assets/images/company-img1.webp"
                    placeholder="blurred"
                    alt="..."
                    width={550}
                    height={360}
                  />
                </span>
              </div>

              <div className="activites-box">
                <span className="activites-card-left2">
                  <StaticImage
                    src="../assets/images/company-img2.webp"
                    placeholder="blurred"
                    alt="..."
                    width={550}
                    height={360}
                  />
                </span>

                <span className="activites-card-right2">
                  <div className="activites-card-h2 h2-title">
                    {_staticTexts.companyHistory}
                  </div>

                  <span
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(content.histoireBody),
                    }}
                  />
                </span>
              </div>
            </div>
          </section>
        </>
      )}

      <Faqs faqs={content.faqs} title={_staticTexts.faqTitle} />

      <Partenaires partners={_partners} />
    </>
  );
};

const CompanyPages = ({ pageContext: content }) => {
  const {
    _companies,
    _countries,
    _others,
    _otherCountries,
    _staticTexts,
    _richTextBlock,
    _topMenus,
    _companyMenus,
    _lang,
    _blogs,
  } = content;

  /**
   * Get sidebar_companies from Contentful and mapping it
   */
  const getSidebarContent = useMemo(() => {
    const newSidebarContent = {
      title: "",
      quote: "",
      signature: "",
    };
    let _sidebarContent = _richTextBlock.find(
      (tb) => tb.title === "sidebar_companies"
    );

    if (_sidebarContent) {
      try {
        _sidebarContent = _sidebarContent.richText.content;

        newSidebarContent.title = _sidebarContent[0].content[0].value;
        newSidebarContent.quote = _sidebarContent[1].content[0].value;
        newSidebarContent.signature = _sidebarContent[2].content[0].value;
      } catch (error) {
        console.log(error);
      }
    }

    return newSidebarContent;
  }, [_richTextBlock]);

  const company = useMemo(() => {
    const { h1, menuTitle, pageTitle, metaDescription, faqsSchema } = content;

    return {
      h1,
      menuTitle,
      pageTitle,
      metaDescription,
      faqsSchema,
    };
  }, []);

  const [sidebarContent] = useState(getSidebarContent);

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      menuSection={content.menuSection}
      menuTitle={content.menuTitle}
      slug={slugify(
        `appeler-${content.pronoun ? content.pronoun + "-" : ""}${
          content.slug
        }-de-l-etranger`
      )}
      ratingValue={content.ratingValue || 4.8}
      ratingCount={content.ratingCount || 165}
      staticTexts={_staticTexts}
      lang={_lang}
      company={company}
    >
      <Helmet>
        <title>{content.pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={content.metaKeywords} />
        <meta
          name="description"
          content={`vous n'arrivez pas à appeler ${
            content.pronoun ? content.pronoun + " " : ""
          }${
            content.menuTitle
          } ? C'est normal, ils utilisent un numéro spécial. Appelez ${
            content.pronoun ? content.pronoun + " " : ""
          }${
            content.menuTitle
          } depuis l'étranger! C'est simple, immédiat et sans tracas.`}
        />
      </Helmet>

      <Countries
        companyTitle={content.menuTitle}
        companyPronoun={content.pronoun}
        countries={[..._countries, ..._otherCountries].sort((a, b) =>
          a.menuTitle.localeCompare(b.menuTitle)
        )}
        staticTexts={_staticTexts}
        lang={_lang}
        mobile={true}
      />
      <PageContent content={content} sidebarContent={sidebarContent} />
    </Layout>
  );
};

export default CompanyPages;
